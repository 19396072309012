import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { UseNotificationsData } from '../../hooks/useNotifications';
import DateUtils from '../../utils/DateUtils';
import NotificationUtils from '../../utils/NotificationUtils';
import Checkbox from '../shared/form-control/Checkbox';
import MailOpenIcon from '../shared/icon/MailOpenIcon';
import RefreshIcon from '../shared/icon/RefreshIcon';
import Loader from '../shared/Loader';
import Tooltip from '../shared/Tooltip';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import { interpolate } from '../../utils/interpolation/InterpolationUtils';

type Props = {
  unread: UseNotificationsData['unread'];
};

const HomePageNotifications: FC<Props> = (props) => {
  const { unread } = props;
  const [checked, setChecked] = useState<Record<string, boolean>>({});

  const [infiniteLoadingRef] = useInfiniteScroll(unread.loadMore, unread.loading);
  const { t } = useTranslation('home-page');

  const anyChecked = useMemo(() => {
    const values = Object.values(checked);
    return values.length > 0 && Object.values(checked).some((x) => x);
  }, [checked]);

  const allChecked = useMemo(() => {
    const values = Object.values(checked);
    return values.length > 0 && unread.notifications.length === values.length && Object.values(checked).every((x) => x);
  }, [checked, unread.notifications.length]);

  const toggleAll = useCallback(() => {
    const result: Record<string, boolean> = {};
    for (const notif of unread.notifications) {
      result[notif.id] = !anyChecked;
    }

    setChecked(result);
  }, [anyChecked, unread.notifications]);

  const markAsRead = useCallback(() => {
    const ids = Object.entries(checked)
      .filter(([_, value]) => value)
      .map(([key, _]) => key);

    unread.markRead(ids);
    setChecked((prev) => {
      const result = { ...prev };
      for (const id of ids) {
        delete result[id];
      }

      return result;
    });
  }, [checked, unread]);

  const refresh = useCallback(() => {
    unread.refresh();
  }, [unread]);

  return (
    <div className="h-full">
      {unread.notifications.length === 0 && (
        <div className="flex h-full flex-col items-center justify-center">
          <div className="font-medium">{t('notifications.empty.heading')}</div>
          <div className="text-dpm-12 max-w-md text-center">{t('notifications.empty.text')}</div>
        </div>
      )}
      {unread.notifications.length > 0 && (
        <div className="relative">
          <div className="after:border-gray-6 sticky top-2 -mt-[1px] flex gap-4 bg-white pb-1 pt-0 font-medium before:absolute before:-left-4 before:-right-4 before:-z-10 before:-mt-4 before:h-8 before:bg-white after:absolute after:-left-4 after:-right-4 after:bottom-0 after:-z-10 after:h-8 after:border-b after:bg-white">
            <Checkbox value={allChecked} indeterminate={anyChecked && !allChecked} onChange={toggleAll} />

            {anyChecked && (
              <Tooltip text={t('notifications.filled.mark-as-read')}>
                {(tooltip) => (
                  <div {...tooltip} className="flex items-center">
                    <MailOpenIcon onClick={markAsRead} className="h-5 w-5" />
                  </div>
                )}
              </Tooltip>
            )}
            {!anyChecked && (
              <Tooltip text={t('notifications.refresh')}>
                {(tooltip) => (
                  <div {...tooltip} className="flex items-center">
                    <RefreshIcon onClick={refresh} className="h-5 w-5" />
                  </div>
                )}
              </Tooltip>
            )}
          </div>
          <div className="-mx-4 ">
            {unread.notifications.map((notification) => {
              const text = interpolate(notification.text, notification.placeholders);
              const linkTo = NotificationUtils.getNotificationUrl(notification.placeholders);
              return (
                <Link
                  to={linkTo}
                  onClick={() => unread.markRead(notification.id)}
                  key={notification.id}
                  className="hover:bg-gray-6 flex min-w-full max-w-0 items-center gap-4 px-4"
                >
                  <span
                    {...mouseAndKeyboardCallbackProps((e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    })}
                  >
                    <Checkbox
                      value={!!checked[notification.id]}
                      onChange={(checked) => setChecked((prev) => ({ ...prev, [notification.id]: checked }))}
                    />
                  </span>
                  <Tooltip text={text} truncatedTextMode>
                    {(tooltip) => (
                      <div {...tooltip} className="my-1 flex-grow truncate">
                        {text}
                      </div>
                    )}
                  </Tooltip>
                  <div className="text-dpm-12 whitespace-nowrap">{DateUtils.formatDateOrTime(new Date(notification.createdUtc))}</div>
                </Link>
              );
            })}
          </div>
          <div className="sticky bottom-0 -mx-4 block h-[10px] bg-white"></div>
          {unread.hasMore && (
            <div ref={infiniteLoadingRef} className="my-4 flex justify-center">
              <Loader size={12} centered={false} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HomePageNotifications;
